$(document).ready(function () {
  //Mobile menu
  let headerMenu = $(".header__nav");
  let hamburger = $(".hamburger")

  hamburger.on("click", function () {
    headerMenu.toggleClass('active-menu')
    hamburger.toggleClass('is-active');
    if (hamburger.hasClass('is-active')) {
      $("body").css("overflow", "hidden" );
    } else {
      $("body").css("overflow", "auto" );
    }
  });

  //Dropdown menu
  $("li.has_submenu").on("click", function () {
    $(this).children(".submenu").slideToggle("slow");
  });

  $(document).click( function(e){
    if ( $(e.target).closest('li.has_submenu').length ) {
      return;
    }
    $('.submenu').slideUp();
  });

  //State menu
  $(".login-header__lang a").click(function () {
    $(".login-header__lang a").removeClass("current-lang");
    $(this).addClass("current-lang");
  });

  // Tabs
  $('.tabs-wrapper').each(function() {
    let ths = $(this);
    ths.find('.tab-item').not(':first').hide();
    ths.find('.tab').click(function() {
      ths.find('.tab').removeClass('active').eq($(this).index()).addClass('active');
      ths.find('.tab-item').hide().eq($(this).index()).fadeIn()
    }).eq(0).addClass('active');
  });
});

//Fix IOS Safari max-height
const appHeight = () => {
  const doc = document.documentElement
  doc.style.setProperty('--app-height', `${window.innerHeight}px`)
}
window.addEventListener('resize', appHeight)
appHeight()
